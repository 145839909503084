



































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  components: {
    MEditor: () => import("@/components/molecules/editor/m-editor.vue"),
  },
  setup(_, { root }) {
    const state = reactive({
      loading: false,
      error: false as boolean | number,
      items: [] as any,
      valid: false,
    });

    const model = reactive({
      isEnabled: true,
      additionalInformation: "",
      participantId: "",
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`/lecturer-website/${root.$route.params.lid}`, {
          params: { type: "lecturer", state: "participant" },
        })
        .then(({ data }) => {
          model.participantId = data.participant
            ? data.participant.id
            : data.lecturer.id;
          model.additionalInformation = data.additionalInformation;
          model.isEnabled = data.isEnabled;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.items = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchData);

    const fetchLecturers = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`/event/${root.$route.params.id}/participant`, {
          params: { type: "lecturer", state: "participant" },
        })
        .then(({ data: { participants } }) => {
          state.items = participants.map((item: any) => ({
            id: item.id,
            firstName: item.firstName,
            lastName: item.lastName,
            addressCity: item.addressCity,
            participant: true,
          }));
        })
        .then(() => {
          axiosInstance
            .get("lecturer")
            .then(({ data: { lecturers } }) => {
              const lecturersList = lecturers.map((item: any) => ({
                id: item.id,
                firstName: item.firstName,
                lastName: item.lastName,
                globalTools: true,
              }));
              state.items.push(...lecturersList);
            })
            .catch((error) => {
              if (error.response.status === 404) {
                state.items = [];
              } else {
                console.log(error);
              }
            })
            .finally(() => {
              state.loading = false;
            });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.items = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchLecturers);

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 409:
          return `${root.$tc(
            "event.panel.website.config.lecturers.add.error409"
          )}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };
    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        isEnabled: model.isEnabled,
        additionalInformation: model.additionalInformation || null,
      };

      state.loading = true;

      axiosInstance
        .put(`/lecturer-website/${root.$route.params.lid}`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc("layout.misc.saveSuccess"),
          });
          setTimeout(() => {
            root.$router.back();
          }, 500);
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    const rules = {
      required: [(v: string) => !!v || `${root.$t("layout.misc.required")}`],
    };

    return { state, model, onSubmit, rules };
  },
});
